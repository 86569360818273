import _ from "lodash";
import React from "react";
import {
	Datagrid,
	DateField,
	ExportButton,
	List,
	TextField,
	TopToolbar,
	fetchRelatedRecords,
	useDataProvider,
	useListContext,
} from "react-admin";
import { ImportButton } from "react-admin-import-csv";

const fields = [
	"email",
	"wish-adopt",
	"age",
	"size",
	"city",
	"genre",
	"have-animal.dog",
	"have-animal.cat",
	"have-children",
	"i-live",
	"ready",
	"sport",
	"createdAt"
];

const QuizList: React.FC<{}> = (props) => {
	const ListActions = (props: any) => {
		const { exporter } = useListContext(props);
		const dataProvider = useDataProvider();

		return (
			<TopToolbar>
				<ExportButton
					exporter={(data) => {
						const dataMaped = data.map((data: any) =>
							fields.reduce((prev, curr) => {
								_.set(prev, curr, _.get(data, curr));
								return prev;
							}, {})
						);
						exporter &&
							exporter(
								dataMaped,
								fetchRelatedRecords(dataProvider),
								dataProvider,
								"quiz"
							);
					}}
					maxResults={10000}
					{...props}
				/>
				<ImportButton resource="quiz" />
			</TopToolbar>
		);
	};

	return (
		<List {...props} actions={<ListActions />}>
			<Datagrid rowClick="edit">
				{fields.map((name) =>
					name === "createdAt" ? (
						<DateField
							source={name}
							label={`quiz.${name}`}
							options={{ day: "2-digit", month: "2-digit", year: "numeric" }}
						/>
					) : (
						<TextField source={name} label={`quiz.${name}`} />
					)
				)}
			</Datagrid>
		</List>
	);
};

export default QuizList;
