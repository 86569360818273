import { TranslationMessages } from 'ra-core';
import frenchMessages from 'ra-language-french';

const customMessages : TranslationMessages = {
  ...frenchMessages,
  resources : {
    'animal'              : 'Les animaux',
    'config.animal-type'  : 'Types d\'animaux',
    'config.breed'        : 'Races',
    'config.criterias'    : 'Critères',
    'config.animal'       : 'Animaux à adopter',
    'config.color'        : 'Couleurs',
    'config.hair'         : 'Poils',
    'accounts'            : 'Les comptes utilisateurs',
    'accounts.user'       : 'Utilisateurs',
    'accounts.association': 'Refuge',
    'accounts.admin'      : 'Administrateurs',
    'accounts.refuge'     : 'Refuges',
    'quiz'                : 'Questionnaires',
    'blog'                : 'Blogs & Images',
    'blog.post'           : 'Blogs',
    'blog.blog-pictures'  : 'Images',
    'config'              : 'Configuration',
    'posts'               : 'Posts',
    'posts.blog'          : 'Articles',
    'posts.faq'           : 'FAQ',
    'posts.partner'       : 'Partenaires',
    'posts.non-paying-partners' : 'Part. non payant',
    'posts.team'          : 'Team',
    'posts.blog-pictures' : 'Images',
    'refuge'              : 'Les refuges',
  },
  login : {
    lostButton : 'Réinitialiser le mot de passe',
    gotoLogin : 'Retour à la connexion',
    gotoLost : 'Mot de passe perdu ?',
    lostExecuted : 'Un mot de passe temporaire a été envoyé à votre adresse email.'
  },

  user : {
    firstConnection : {
      pageTitle : 'Première connexion',
      title : 'Bienvenue',
      description : 'Vous venez de vous connecter pour la première fois. Le mot de passe que vous avez reçu par email n\'est valable qu\'une fois. Merci de bien voulour le modifier ci-dessous.',
      password1 : 'Nouveau mot de passe',
      password2 : 'Confirmation',
      submit : 'Envoyer',
      wrongPassword : 'Le mot de passe doit avoir au moins 8 caractères dont au moins 1 chiffre et une lettre'
    },
    settings : {
      pageTitle : 'Réglages',
      title : 'Bienvenue %{name}',
      email : 'Email',
      password : 'Nouveau mot de passe',
      firstName : 'Prénom',
      lastName : 'Nom'
    },
    pristine : {
      'true'  : 'Non validé',
      'false' : 'Validé'
    },
    tabs : {
      generic : 'Général',
      quiz : 'Quiz',
      favoritesAnimals : 'Animaux favoris',
      favoritesRefuges : 'Refuges favoris',
    },
    firstname : 'Prénom',
    lastname : 'Nom',
    email : 'Email',
    phone : 'Téléphone',
    address : 'Adresse',
    hasCompleteQuiz : 'Quiz complété',
    refugeId : 'Refuge',
    createdAt : 'Créé le'

  },
  usermenu : {
    profile : "Réglages",
  },
  breed : {
    animalType : 'Animal',
    key : 'Clé unique',
    value : 'Nom de la race',
    lang : 'Langue',
    filter : 'Filtrer par type',
    uploadDialog : {
      button : 'Importer',
      title : 'Téléchargement des fichiers',
      helper : 'Glissez-deposez ou cliquez ici',
      select : 'Sélectionnez un type d\'animal',
      description : `Glissez-deposez ou cliquez dans le rectangle ci-dessous pour télécharger les fichiers à traiter.`,
      uploadButton : 'Télécharger',
      cancel : 'Annuler',
    }
  },
  criteria : {
    key : 'Clé unique',
    value : 'Nom',
    lang : 'Langue'
  },
  animal : {
    hairs      : 'Poils',
    name       : 'Nom',
    sponsorShip: 'Animal à parrainer',
    adopted    : 'Adopté',
    animalType : 'Type d\'animal',
    breeds     : 'Races',
    description: 'Description',
    address    : 'Adresse',
    health     : 'Santé',
    weight     : 'Poids en kg',
    colors     : 'Couleurs',
    size       : 'Taille',
    birthday   : 'Date de naissance',
    identifier : 'N° d\'indentification',
    vaccined   : 'Vacciné',
    verified   : 'Vérifié',
    refuge     : 'Refuge',
    isActive   : 'En cours',
    isNotActive: 'Terminé',
    filter     : 'Filtrer par type',
    tabs       : {
      generic  : 'Général',
      criterias: 'Critères',
      photos   : 'Photos'
    },
    sections : {
      identity        : 'Identité',
      refuge          : 'Refuge',
      generalCriterias: 'Critères généraux'
    },
    filters : {
      type     : 'Type',
      genre    : 'Genre',
      age      : 'Âge',
      size     : 'Taille',
      criterias: 'Critères'
    }
  },
  animalType : {
    key  : 'Clé unique',
    value: 'Nom',
    lang : 'Langue'
  },
  color : {
    key : 'Clé unique',
    value : 'Nom',
    lang : 'Langue'
  },
  hair : {
    key  : 'Clé unique',
    value: 'Nom',
    lang : 'Langue'
  },
  refuge : {
    firstname   : 'Prénom',
    lastname    : 'Nom',
    email       : 'Email',
    mail        : 'Email',
    animals     : 'Animaux',
    phone       : 'Téléphone',
    name        : 'Nom',
    siret       : 'SIRET',
    address     : 'Adresse',
    rna_hk      : 'RNA ou HK',
    slug        : 'Slug',
    description : 'Description',
    metaData    : 'Métadonnées',
    photos      : 'Photos',
    hidePhone   : 'Masquer le téléphone',
    hostFamily  : 'Famille d\'accueil',
    donationLink: 'Lien de don',
    website     : 'Site web',
    createdAt   : 'Créé le',
    tabs        : {
      generic : 'Général',
      user    : 'Référent',
      animal  : 'Animaux',
      photos  : 'logo'
    },
    sections : {
      infos : 'Informations',
      socials : 'Réseaux sociaux',
      other : 'Autres'
    },
  },
  "quiz" : {
    name           : 'Questionniaire',
    email          : 'Email',
    "have-animal.dog"  : "J'ai des chiens",
    "have-animal.cat"  : "J'ai des chats",
    "have-children": "Et des enfants",
    "wish-adopt"   : "Je souhaite adopter",
    "genre"        : "J'aimerais que ça soit",
    "age"          : "Et concernant son âge",
    "size"         : "Et pour sa taille",
    "i-live"       : "Je vis",
    "city"         : "Choix de la ville",
    "ready"        : "Je suis prêt.e à faire",
    "sport"        : "Je veux faire du sport avec mon animal",
    "createdAt"    : 'Créé le',
  },
  "old-quiz" : {
    name: 'Nom du questionnaire',
    tabs: {
      questions: 'Questions',
      quiz     : 'Questionniaire'
    }
  },
  questions : {
    quizCreateTitle: "Création d'une question",
    quizEditTitle  : "Modification de la question",
    key            : "Clé unique",
    value          : "Intitulé de la question",
  },
  post : {
    type       : 'Type',
    title      : "Titre",
    intro      : 'Introduction',
    content    : 'Contenu',
    resume     : 'Résumé',
    category   : 'Categorie',
    link       : 'Lien',
    slug       : 'slug',
    addImage   : 'Ajoutez des images',
    insert     : 'Insérer',
    createdAt  : 'Créé le',
    highlighted: 'Mis en avant',
    tabs       : {
      generic: 'Général',
      photos : 'Photos',
      editor : 'Éditeur'
    },
    photo : {
      main     : 'Photo principale',
      thumbnail: 'Vignette'
    }
  },
  'blog-pictures' : {
    add : 'Ajouté le'
  },
  partners: {
    preference: "Pages préférées"
  },
  "Button Clicked":"clic sur un bouton",
   "lname":"Nom",
  "First name":"Prénom",
  "Phone":"Téléphone",
  "More Info":"Plus d'informations",
  "Refuge Email":"E-mail du refuge",
  "Animal Name":"Nom de l'animal",
  "Link":"Lien",
  "Language":"Langue",
  "Created At":"Créé à"
};

export default customMessages;
