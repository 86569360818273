import React, { useEffect } from 'react';
import { 
  TextInput, 
  ReferenceInput, 
  SelectInput, 
  DateInput, 
  BooleanInput, 
  ImageInput, 
  ImageField, 
  useGetList, 
  NumberInput, 
  FormDataConsumer, 
  AutocompleteInput,
  TabbedForm,
  FormTab,
  ReferenceArrayInput,
  AutocompleteArrayInput, 
  useTranslate,
  useInput,
  useRedirect
} from 'react-admin';
import { Grid, Box, Typography } from '@mui/material';
import _ from 'lodash';
import { Criteria, Post } from '../../types';

const ageChoices = [{id : 'young', name : 'Jeune'}, {id : 'adult', name : 'Adulte'}, {id : 'senior', name : 'Senior'}];
const genreChoices = [{id : 'male', name : 'Mâle'}, {id : 'female', name : 'Femelle'}];
const sizeChoices = [{id : 'small', name : 'Petite taille'}, {id : 'medium', name : 'Moyenne taille'}, {id : 'big', name : 'Grande taille'}];

interface AnimalFormProps {
  isEdit?: boolean; 
  data?: any;
}

const Colors = () => {
  const { data, isLoading } = useGetList('color', { pagination : {page : 1, perPage : 10000}}); //filter : { lang : 'fr' }
  return (
    <AutocompleteArrayInput 
      optionValue='key' 
      label='animal.colors' 
      optionText="value"  
      source='colors' 
      isLoading={isLoading}
      choices={data || []}
    />
  );
}

const Breeds = () => {
  const { data, isLoading } = useGetList('breed', { pagination : {page : 1, perPage : 10000} }); //filter : { lang : 'fr' }
  return (
    <AutocompleteArrayInput 
      optionValue='key' 
      label='animal.breeds' 
      optionText="value"  
      source='breeds' 
      isLoading={isLoading}
      choices={data || []}
    />
    );
  }

const AnimalsTypes = () => {
  const { data, isLoading } = useGetList('animal-type', { pagination : {page : 1, perPage : 10000} }); //filter : { lang : 'fr' }
  return (
    <SelectInput 
        label="animal.animalType"
        source="animalTypeId"
        choices={data?.sort((a,b) => a.key < b.key ? -1 : 1) || []}
        optionText="value"
        optionValue="key"
        isLoading={isLoading}
        required
        sx={{width : '100%'}}
    />
  );
}

const Hairs = () => {
  const { data, isLoading } = useGetList('hair', { pagination : {page : 1, perPage : 10000}}); //filter : { lang : 'fr' }
  return (
    <SelectInput 
        label="animal.hairs"
        source="hairs"
        choices={data?.sort((a,b) => a.key < b.key ? -1 : 1) || []}
        optionText="value"
        optionValue="key"
        isLoading={isLoading}
        required
        sx={{width : '100%'}}
    />
  );
}


const AnimalForm : React.FC<AnimalFormProps>= (props) => {
  const {data : listCriterias = []} = useGetList('criterias');
  const translate = useTranslate();

  const redirect = useRedirect();

  const postSave = (data: any) => {
    redirect('edit', 'post', 1)
  }
  //onSubmit={postSave}
  return (
    <TabbedForm warnWhenUnsavedChanges {...props}  >
      <FormTab label='animal.tabs.generic' >
        <div>
          <Grid container width={{ xs: '100%', xl: 800 }} spacing={2}>
            <Grid item xs={12} md={8}>
              <Typography variant="h6" gutterBottom>
                  {translate(
                      'animal.sections.identity'
                  )}
              </Typography>
              <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <Box>
                    <TextInput source="name" label='animal.name' required />
                  </Box>
                  <Box>
                    <DateInput source="birthday" label='animal.birthday' required />
                  </Box>
                  <Box>
                    <TextInput source="identifier" label='animal.identifier' required />
                  </Box>
                  <Box>
                    <TextInput source="address" label='animal.address' style={{width : 500}} required />
                  </Box>
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <TextInput source="description" label='animal.description' multiline style={{width : 500, marginLeft : 100}} sx={{textarea : {height : '250px !important'}}} />
                </Box>
              </Box>
              <Typography variant="h6" gutterBottom>
                  {translate(
                      'animal.sections.refuge'
                  )}
              </Typography>
              <Box>
                <ReferenceInput reference='refuge' source='refugeId' required>
                  <AutocompleteInput optionText="name" isRequired aria-required />
                </ReferenceInput>
              </Box>
              <Typography variant="h6" gutterBottom>
                  {translate(
                      'animal.sections.generalCriterias'
                  )}
              </Typography>
              <Box>
                <TextInput source="health" label='animal.health' multiline style={{width : 400}} sx={{textarea : {height : '60px !important'}}} required />
              </Box>
              <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <Box>
                    <AnimalsTypes />
                  </Box>
                  <Box>
                    <SelectInput source="size" label='animal.size' choices={sizeChoices} required sx={{width : '100%'}} />
                  </Box>
                  <Box>
                    <Breeds />
                  </Box>
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <Box>
                    <SelectInput source='genre' choices={genreChoices} required sx={{width : '100%'}} />
                  </Box>
                  <Box>
                    <SelectInput source='age' choices={ageChoices} required sx={{width : '100%'}} />
                  </Box>
                  <Box>
                    <Colors />
                  </Box>
                </Box>
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} >
                <NumberInput source="weight" label='animal.weight' step={0.1} />
                <Hairs />
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <BooleanInput source="vaccined" label="animal.vaccined" />
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <BooleanInput source="sponsorShip" label="animal.sponsorShip" />
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <BooleanInput source="adopted" label="animal.adopted" />
              </Box>
            </Grid>
          </Grid>
        </div>
      </FormTab>
      <FormTab label='animal.tabs.photos'>
        <ImageInput source="photos" label="Image" accept="image/*" multiple={true} isRequired >
          <ImageField source="src" title="title" sx={{ img : {width : '250px !important', height : '250px !important'}}} />
        </ImageInput>
      </FormTab>
      <FormTab label='animal.tabs.criterias'>
        {
          props.isEdit ?
          <>
            <FormDataConsumer>
              {({ formData }) => {
                  return formData?.criterias?.map(({key, value}: Criteria, index: number) => {
                    const exist = _.some(listCriterias, {key});
                    if(exist){
                      return (
                        <BooleanInput label={listCriterias?.filter(({lang}) => lang === 'fr')?.find(({key: keyC}) => keyC === key)?.value || ''} source={`criterias[${index}].value`} />
                      )
                    }
                  })
              }}
            </FormDataConsumer>  
            <FormDataConsumer>
              {({ formData }) => {
                const criteriasNotSetted = listCriterias?.filter(({key, lang}) => !_.includes(formData?.criterias?.map(({key}: Criteria) => key), key) && lang === 'fr')
                  return criteriasNotSetted?.map(({key, lang}: Criteria) => {
                    if(lang === 'fr'){
                      return (
                        <BooleanInput label={listCriterias?.find(({key: keyC}) => keyC === key)?.value || ''} source={`newCriterias.${key}`} />
                      )
                    }
                  })
              }}
            </FormDataConsumer>  
          </> :
          listCriterias?.map(({key, value, lang}) => lang === 'fr' && <BooleanInput label={value} source={`criterias.${key}`} />)
        }
      </FormTab>
    </TabbedForm>
  );
};

export default AnimalForm;
