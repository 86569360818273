import { CreateParams, UpdateParams, DataProvider } from 'ra-core';
import _ from 'lodash';
import config from './../configprovider';
import createDefaultDataProvider from './default';
import uploadsPicturesProvider from './uploadsPictures';
import { fetchUtils } from 'react-admin';
import flatten from 'flat';
import { stringify } from 'query-string';
import { serialize } from '../../shared/utils/tools';
import { v4 as uuidv4 } from 'uuid';
import slugify from 'slugify';

const httpClient = fetchUtils.fetchJson;

export const modelDataProvider : DataProvider = createDefaultDataProvider(config('MODEL_API_URL'), 30);

const queryFilterArray = (query : string) : string =>
  query?.replace(/f\.([a-zA-Z]+)\.(gt|ge|lt|le)=/g, 'f=$1;$2;');

export const createAnimalDataProvider = (apiUrl : string, ttl = 0) : DataProvider => ({
  ...modelDataProvider,
    create : async (resource, params) => {
      let newParams: CreateParams<any>;
      const refuge = await modelDataProvider.getOne('refuge', {id : params?.data?.refugeId});

      let photoIds: string[] = [];
      if(params?.data?.photos?.length){
        const photosPromises = params?.data?.photos?.map((photo: any) => {
          const newParams = {
              data : {
                __file__ : photo
              }
            }
          return uploadsPicturesProvider.create('animal-pictures', newParams)
        })

        const photosCreated = await Promise.all(photosPromises);
        photoIds = photosCreated?.map((photo) => photo.data.id)
      }

    if (!Array.isArray(params.data.criterias)) {
      const criterias = Object.entries(params.data.criterias)?.map(([key, value]) => ({ key, value }))
      newParams = {
        ...params,
        data: {
          ...params.data,
          criterias,
          photos: photoIds,
          uuid: uuidv4(),
          adopted: false,
          slug: slugify(`${refuge?.data?.name?.trim()} ${params?.data?.name}`, {
            replacement: '--',
            lower: true,
            remove: /[^\w\s-\.]/g,
            trim: true,
          })
            .replace(/\./g, '')
            .replace(/\(--\d+\)/, '')
        }
      }
    } else {
      newParams = {
        ...params,
        data: {
          ...params.data,
          photos: photoIds,
          uuid: uuidv4(),
          adopted: false,
          slug: slugify(`${refuge?.data?.name?.trim()} ${params?.data.name}`, {
            replacement: '--',
            lower: true,
            remove: /[^\w\s-\.]/g,
            trim: true,
          })
            .replace(/\./g, '')
            .replace(/\(--\d+\)/, '')
        }
      };
    }
    return modelDataProvider.create(resource, newParams)
  },
  update: async (resource, params) => {
    let newParams: UpdateParams<any>;
    let photoIds: string[] = [];
    const { previousData } = params;

    const newPhotosIds = (params?.data?.photos || []).map(({id} : {id : string}) => id).filter((id : any) => !!id);
    const previousPhotosIds = (previousData?.photos || []).map(({id} : {id : string}) => id);

    const picturesToDelete = previousPhotosIds.filter((id : string) => !newPhotosIds.includes(id));
    const picturesToCreate = params?.data?.photos?.filter((photo: any) => !!photo.rawFile);
    const existingPictures = newPhotosIds.filter((id : string) => previousPhotosIds.includes(id));

    if(picturesToDelete?.length){
      for(let photoId of picturesToDelete) {
        await uploadsPicturesProvider.delete('animal-pictures', {id : photoId});
      }
    }
    if(picturesToCreate?.length){
      const photosPromises = picturesToCreate?.map((photo: any) => {
        const newParams = {
            data : {
              __file__ : photo
            }
          }
        return uploadsPicturesProvider.create('animal-pictures', newParams)
      })

      const photosCreated = await Promise.all(photosPromises);
      photoIds = photosCreated?.map((photo) => photo.data.id)
    }

    if(params.data.newCriterias){
      const criterias = Object.entries(params.data.newCriterias)?.map(([key, value]) => ({key, value}))
      newParams = {
        ...params,
        data : {
          ...params.data,
          criterias: [...(params?.data.criterias || []), ...criterias],
          photos : [...existingPictures, ...photoIds].filter((id : string) => !!id)
        }
      }
    } else {
      newParams = {
        ...params,
        data : {
          ...params.data,
          photos : [...existingPictures, ...photoIds].filter((id : string) => !!id)
        }
      }
    }
    return modelDataProvider.update(resource, newParams)
  },
  getOne: async (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, { user: { authenticated: true, token: `Bearer ${localStorage.getItem('token')}` } }).then(({ json }: any) => {
      return {
        data: { ...json, photos: json.photos?.map((photoId: string) => ({ id: photoId,
          src: photoId.startsWith('https://') ? photoId : `${config('UPLOAD_API_URL')}/animal-pictures/${photoId}/binary`,

         })) },
        ...(ttl ? { validUntil: new Date(Date.now() + ttl * 1000) } : {})
      }
    }),

  getList: async (resource, params) => {
    const { page, perPage } = params.pagination || {};
    const { field, order } = params.sort || {};
    const filter = { ...params.filter }; // Copy the filter object
    console.log("paramsparamsparamsparamsparams", params)
    // Ensure boolean filters are correctly set
    if (filter.adopted !== undefined) {
      filter.adopted = Boolean(filter.adopted);
    }
    if (filter.sponsorShip !== undefined) {
      filter.sponsorShip = Boolean(filter.sponsorShip);
    }

    // Ensure region is included in the filter
    if (params.filter?.region) {
      filter.region = params.filter.region;
    }

    let url = '';
    let query = {
      ...filter,
      sort: [[field, order]],
      page: JSON.stringify(page - 1),
      pageSize: JSON.stringify(perPage),
      // region: JSON.stringify(params.filter?.region)
    };

    if (filter.criterias) {

      query.criterias = filter.criterias;
      url = `${apiUrl}/${resource}?${serialize(query, '')}`;
    } else {
      if (resource === "animal") {
        url = `${apiUrl}/${resource}?${queryFilterArray(stringify(query))}`;
      }
      else {
        url = `${apiUrl}/${resource}?${queryFilterArray(stringify(query))}`;
      }
    }


    return httpClient(url, { user: { authenticated: true, token: `Bearer ${localStorage.getItem('token')}` } }).then(({ json }: any) => ({
      data: [...json.data],
      total: json.pagination.itemsCount,

      ...(ttl ? { validUntil: new Date(Date.now() + ttl * 1000) } : {})
    }));
  },

})